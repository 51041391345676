import React, { Component } from 'react'
import queryString from 'query-string'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

const CHECK_ACCEPT = gql`
  query acceptable($targetYearMonth: String!){
    midSchMonthlyQuestionsAcceptable(targetYearMonth: $targetYearMonth)
  }
`

class InputGateway extends Component {
  constructor (props) {
    super(props)
    this.props = props
  }

  render() {
    const { location } = this.props
    const parsed = queryString.parse(location.search)
    const targetYearMonth = parsed.target_year_month
    const authToken = parsed.auth_token
    // const answers = JSON.parse('['+Object.values(window.sessionStorage).sort().join(',')+']')
    console.log(parsed)

    return (
      <Query query={CHECK_ACCEPT} variables={{ targetYearMonth }}>
        {({ data }) => {

          if (data.midSchMonthlyQuestionsAcceptable) {
            return (
              <>
                <Typography variant="h5" component="h1" gutterBottom>毎月チェック</Typography>
                <p>お使いのアプリを選択してください。両方のアプリを使っている場合は、今月よく使ったアプリを選択してください。</p>
                <Box my={5}>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <Button href={`/survey.html?target_year_month=${targetYearMonth}&auth_token=${authToken}&division_code=02`} variant="outlined" color="primary">中学版アプリを利用中</Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button href={`/survey.html?target_year_month=${targetYearMonth}&auth_token=${authToken}&division_code=03`} variant="outlined" color="primary">高校版アプリを利用中</Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )
          } else {
            return (
              <>
                <p>アンケート回答の受付期間ではありません。受付は当月20日以降です。</p>
              </>
            )
          }

        }}
      </Query>
    )
  }

}

export default InputGateway
