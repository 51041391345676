import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Home from './Home'
import InputGateway from './InputGateway'
import InputSurvey from './InputSurvey'
import SurveyResult from './SurveyResult'
import SurveyError from './SurveyError'
import SurveyThanks from './SurveyThanks'
import Header from './Header'

export default function App() {
  return (
    <>
      <Header />
      <Container maxWidth="md">
        <Box my={4}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/input.html" component={InputGateway} />
            <Route exact path="/survey.html" component={InputSurvey} />
            <Route exact path="/result.html" component={SurveyResult} />
            <Route exact path="/error.html" component={SurveyError} />
            <Route exact path="/thanks.html" component={SurveyThanks} />
          </Switch>
        </Box>
      </Container>
    </>
  )
}
