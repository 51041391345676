import React, { Component } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'

import { Query, Mutation } from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import InputSelect from './InputSelect'
import Button from '@material-ui/core/Button'

const GET_QUESTION = gql`
  query question($targetYearMonth: String!, $schoolDivisionCode: String!){
    midSchMonthlyQuestions(targetYearMonth: $targetYearMonth, schoolDivisionCode: $schoolDivisionCode){
      answerRequired
      id
      question
      schoolDivisionCode
      selections
    }
  }
`

export const SUBMIT_SURVEY = gql`
  mutation answerMonthlyQuestions($authToken: String!, $targetYearMonth: String!, $answers: [MonthlyQuestionAnswers!]!, $schoolDivisionCode: String!) {
    answerMidSchMonthlyQuestions(authToken: $authToken, targetYearMonth: $targetYearMonth, schoolDivisionCode: $schoolDivisionCode, answers: $answers){
      errors {
        code
        messages
      }
    }
  }
`

class InputSurvey extends Component {
  constructor (props) {
    super(props)
    this.props = props
    this.handleUpdateAnswers = this.handleUpdateAnswers.bind(this)
    this.handleRemoveAnswers = this.handleRemoveAnswers.bind(this)
    this.state = {
      error: '',
      allAnswers: [],
    }
  }

  handleUpdateAnswers(questionId, answer) {
    // sessionStorage.setItem(questionId, JSON.stringify({questionId: Number(questionId), answers: answer}))
    this.state.allAnswers.push({questionId: Number(questionId), answers: answer})

    const answerCopy = this.state.allAnswers.slice()

    const group = answerCopy.reduce((result, current) => {
      const element = result.find((p) => p.questionId === current.questionId)
      if (element) {
        element.answers = current.answers
      } else {
        result.push({
          questionId: current.questionId,
          answers: current.answers
        })
      }

      result.sort(function(a, b) {
        if (a.questionId > b.questionId) {
          return 1
        } else {
          return -1
        }
      })

      return result
    }, [])

    this.setState({'allAnswers': group})
    // console.log(this.state.allAnswers)
  }

  handleRemoveAnswers(questionId) {
    const answerCopy = this.state.allAnswers.slice()

    const index = answerCopy.findIndex((v) => v.questionId === questionId)
    answerCopy.splice(index, 1)

    const group = answerCopy.reduce((result, current) => {
      const element = result.find((p) => p.questionId === current.questionId)
      if (element) {
        element.answers = current.answers
      } else {
        result.push({
          questionId: current.questionId,
          answers: current.answers
        })
      }

      result.sort(function(a, b) {
        if (a.questionId > b.questionId) {
          return 1
        } else {
          return -1
        }
      })

      return result.sort()
    }, [])

    this.setState({'allAnswers': group})
    // console.log(this.state.allAnswers)
  }

  render() {
    console.log(this.props)
    const { location } = this.props
    const parsed = queryString.parse(location.search)
    const targetYearMonth = parsed.target_year_month
    const authToken = parsed.auth_token
    const schoolDivisionCode = parsed.division_code
    // const answers = JSON.parse('['+Object.values(window.sessionStorage).sort().join(',')+']')
    console.log(parsed)

    return (
      <Query query={GET_QUESTION} variables={{ targetYearMonth, schoolDivisionCode }}>
        {({ data, loading, error }) => {
          if (loading) return <p>毎月チェックの質問を取得中</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          return (
            <>
              <Typography variant="h5" component="h1" gutterBottom>
                毎月チェック {schoolDivisionCode === '02' ? `（中学版）` : `（高校版）` }
              </Typography>
              <p>各質問の選択肢から回答を選択してください（複数選択可。※印は回答必須項目）</p>
              <form autoComplete="off">
                {data.midSchMonthlyQuestions.map(question => (
                  <Box my={5} key={question.id}>
                    <p htmlFor={question.id}><strong>{question.question}</strong>&nbsp;<span>{question.answerRequired ? '※' : ''}</span></p>
                    <InputSelect question={question} questionId={question.id} isRequired={question.answerRequired} handleUpdateAnswers={this.handleUpdateAnswers} handleRemoveAnswers={this.handleRemoveAnswers} />
                  </Box>
                ))}
                <Mutation
                  mutation={SUBMIT_SURVEY}
                  variables={{ authToken: authToken, targetYearMonth: targetYearMonth, schoolDivisionCode: schoolDivisionCode, answers: this.state.allAnswers }}
                  onCompleted={data => this._confirm(data)}
                >
                  {mutation => (
                    <>
                      <Button variant="contained" color="primary" onClick={mutation} disabled={this.state.allAnswers[0] && this.state.allAnswers[1] && this.state.allAnswers[2] && this.state.allAnswers[3] && this.state.allAnswers[4] && this.state.allAnswers[5] && this.state.allAnswers[6] && this.state.allAnswers[7] && this.state.allAnswers[8] ? false : true}>
                        送信する
                      </Button>
                    </>
                  )}
                </Mutation>
              </form>
            </>
          )
        }}
      </Query>
    )
  }

  _confirm = async data => {
    const errorCode = data.answerMidSchMonthlyQuestions.errors.code

    console.log(errorCode)
    if (!errorCode) {
      this.props.history.push(`/thanks.html`)
    } else {
      this.props.history.push(`/error.html`)
    }
  }

}

InputSurvey.propTypes = {
  questionId: PropTypes.number.isRequired,
}

export default InputSurvey
