import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'

class SurveyError extends Component {
  constructor (props) {
    super(props)
    this.props = props
  }

  render() {
    // const { location } = this.props

    return (
      <>
        <Typography variant="h5" component="h1" gutterBottom>
          毎月チェックエラー
        </Typography>
        <p>回答受付期間外、もしくは既に当月の質問には回答済みです。</p>
      </>
    )
  }

}

export default SurveyError
