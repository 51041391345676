import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import queryString from 'query-string'

class Home extends Component {
  constructor (props) {
    super(props)
    this.props = props
  }


  render() {
    const { location } = this.props
    const parsed = queryString.parse(location.search)
    console.log(parsed)

    return (
      <div>
        <p>毎月チェックの入力ならびに閲覧は、手元に送付されたURLをクリックしてください。</p>
      </div>
    )
  }
}

export default Home
