import { createMuiTheme } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary:lightBlue,
    error: red,
    background: {
      default: '#fff'
    }
  }
})

export default theme
