import React, { Component } from 'react'
import { withRouter } from 'react-router'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

class Header extends Component {
  constructor (props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6">
              トライAI5教科 毎月チェック
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default withRouter(Header)
