import React, { Component } from 'react'
import queryString from 'query-string'

import { Query } from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const GET_USER = gql`
  query user($targetYearMonth: String!, $authToken: String!){
    user(authToken: $authToken) {
      midSchMonthlyAnswer(targetYearMonth: $targetYearMonth) {
        schoolDivisionCode
        updatedAt
      }
    }
  }
`

const GET_ANSWER = gql`
  query answer($targetYearMonth: String!, $authToken: String!, $schoolDivisionCode: String!){
    midSchMonthlyQuestions(targetYearMonth: $targetYearMonth, schoolDivisionCode: $schoolDivisionCode) {
      id
      question
      answerRequired
    }
    user(authToken: $authToken) {
      midSchMonthlyAnswer(targetYearMonth: $targetYearMonth) {
        questionAnswers {
          midSchMonthlyQuestionId
          answers
        }
      }
    }
  }
`

class SurveyResult extends Component {
  constructor (props) {
    super(props)
    this.props = props
    this.state = {
      allAnswers: []
    }
  }

  render() {
    const { location } = this.props
    // console.log(this.props)
    const parsed = queryString.parse(location.search)
    const targetYearMonth = parsed.target_year_month
    const authToken = parsed.auth_token
    // console.log(parsed)

    return (
      <Query query={GET_USER} variables={{ targetYearMonth, authToken }}>
        {({ data, loading, error }) => {
          console.log(data)
          if (loading) return <p>毎月チェックの回答を取得中</p>
          if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

          if (!data.user) return <p>ユーザーデータが取得できません。システム管理者にお問い合せください。</p>
          if (data.user.midSchMonthlyAnswer === null) return <p>対象月の毎月チェックの回答はありません。</p>

          const schoolDivisionCode = data.user.midSchMonthlyAnswer.schoolDivisionCode

          return (
            <Query query={GET_ANSWER} variables={{ targetYearMonth, authToken, schoolDivisionCode }}>
              {({ data, loading, error }) => {
                if (loading) return <p>毎月チェックの回答を取得中</p>
                if (error) return <p>エラーが発生しました。システム管理者にお問い合せください。</p>

                if (!data.user.midSchMonthlyAnswer) {
                  return (
                    <>
                      <Typography variant="h5" component="h1" gutterBottom>
                        毎月チェック回答
                      </Typography>

                      <p>対象月の毎月チェックの回答はありません。</p>
                    </>
                  )
                } else {
                  console.log(data)
                  return (
                    <>
                      <Typography variant="h5" component="h1" gutterBottom>
                        毎月チェック回答
                      </Typography>

                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[0].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[0].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[1].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[1].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[2].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[2].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[3].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[3].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[4].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[4].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[5].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[5].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[6].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[6].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[7].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[7].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box my={5}>
                        <p><strong>{data.midSchMonthlyQuestions[8].question}</strong></p>
                        <ul>
                          {data.user.midSchMonthlyAnswer.questionAnswers[8].answers.map(answer => (
                            <li>{answer}</li>
                          ))}
                        </ul>
                      </Box>
                    </>
                  )
                }

              }}
            </Query>
          )}
        }
      </Query>
    )
  }

}

export default SurveyResult
