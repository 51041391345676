import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'

const IndicatorsContainer = props => {
  return (
    <div style={{ visibility: 'hidden' }}>
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

class InputSelect extends Component {
  constructor (props) {
    super(props)
    this.props = props
  }

  state = {
    selectedOption: null,
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption })
    const questionId = this.props.questionId

    if (selectedOption) {
      const answer = selectedOption.map(x => x.value)
      this.props.handleUpdateAnswers(Number(questionId), answer)
    } else {
      const answer = []
      this.props.handleRemoveAnswers(Number(questionId), answer)
    }
  }

  render() {
    const { question } = this.props
    const { selectedOption } = this.state

    // console.log(question)

    return (
      <>
        <Select
          id={question.id}
          name={question.id}
          isMulti
          components={{ IndicatorsContainer }}
          value={selectedOption}
          // clearIndicator={false}
          onChange={this.handleChange}
          options={question.selections.map(selection => ({ value: `${selection}`, label: `${selection}`}))}
        />
      </>
    )
  }
}

InputSelect.propTypes = {
  questionId: PropTypes.number.isRequired
}

export default InputSelect
